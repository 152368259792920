import React, { Component } from "react";
import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";
import { Typography } from "@material-ui/core";
import { Menu } from "antd";
import { MenuUnfoldOutlined } from "@ant-design/icons";
const { SubMenu } = Menu;

class DepartmentNavigation extends Component {
  state = {
    current: "",
  };

  handleClick = (e) => {
    this.props.history.push(e.key);
    this.setState({
      current: e.key,
    });
  };
  render() {
    let menu = [
      {
        title: "ABOUT",
        path: `/department/${this.props.dept_code}`,
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "PROFILE",
        path: `/department/${this.props.dept_code}/profile`,
        hasSubnav: false,
        subnav: [],
      },
      // {
      //     title: 'VISION',
      //     path: `/department/${this.props.dept_code}/vision`,
      //     hasSubnav: false,
      //     subnav: []
      // },
      // {
      //     title: 'DEPARTMENT AT A GLANCE',
      //     path: `/department/${this.props.dept_code}/glance`,
      //     hasSubnav: false,
      //     subnav: []
      // },
      {
        title: "FACULTY MEMBERS",
        path: `/department/${this.props.dept_code}/faculty`,
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "COURSES & PROGRAMMES",
        path: `/department/${this.props.dept_code}/course`,
        hasSubnav: false,
        subnav: [],
      },
      // {
      //     title: 'SYLLABUS',
      //     path: `/department/${this.props.dept_code}/syllabus`,
      //     hasSubnav: false,
      //     subnav: []
      // },
      {
        title: "JOURNAL & MAGAZINES",
        path: `/department/${this.props.dept_code}/journal`,
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "EVENTS",
        path: `/department/${this.props.dept_code}/events`,
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "NOTIFICATIONS",
        path: `/department/${this.props.dept_code}/notifications`,
        hasSubnav: false,
        subnav: [],
      },
      // {
      //     title: 'ACHIEVEMENTS',
      //     path: `/department/${this.props.dept_code}/achievements`,
      //     hasSubnav: false,
      //     subnav: []
      // },
      {
        title: "COs & POs",
        path: `/department/${this.props.dept_code}/co_po`,
        hasSubnav: false,
        subnav: [],
      },
      // {
      //     title: 'RESEARCH',
      //     path: `/department/${this.props.dept_code}/research`,
      //     hasSubnav: false,
      //     subnav: []
      // },
      {
        title: "GALLERY",
        path: `/department/${this.props.dept_code}/gallery`,
        hasSubnav: false,
        subnav: [],
      },
      {
        title: "ALUMNI",
        path: `/department/${this.props.dept_code}/alumni`,
        hasSubnav: false,
        subnav: [],
      },
      // {
      //     title: 'CONTACT DETAILS',
      //     path: `/department/${this.props.dept_code}/contact`,
      //     hasSubnav: false,
      //     subnav: []
      // }
    ];
    return (
      <div>
        {/* <div style={{padding: '1px 10px 1px 10px'}}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            height: '15vh',
                            width: '100%',
                            alignItems: 'center',
                            cursor: 'pointer'
                        }}

                        onClick={() => this.props.history.push('/')}
                    >
                        <div
                            style={{
                                align: 'center'
                            }}
                        >
                            <img
                                src={'/images/header/logo.png'}
                                style={{
                                    width: '70px'
                                }}
                            />
                        </div>
                        <div
                            style={{
                                paddingLeft: '2vw'
                            }}
                        >
                            <Typography variant="h6" className={`departmental-header-text`}>{this.props.data.dept_name_as}, বাহনা মহাবিদ্যালয়</Typography>
                            {/* <Typography variant="subtitle2">বাহনা মহাবিদ্যালয়</Typography> *
                            <Typography variant="h6" className={`departmental-header-text`}>{this.props.data.dept_name}, PANDU College</Typography>
                            {/* <Typography variant="subtitle2">PANDU College</Typography> *
                        </div>
                    </div>
                </div> */}
        {/* <Navbar
          bg="dark"
          expand="lg"
          className="justify-content-center"
          style={{ backgroundColor: "black !important" }}
        >
         
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            style={{ filter: "invert()" }}
          />
          <Navbar.Collapse>
            <Nav className="mr-auto">
              {menu.map((el, index) => (
                <Nav.Link
                  key={index}
                  className="text-white"
                  style={{
                    fontSize: "0.9em",
                    fontFamily: "Open Sans !important",
                  }}
                  onClick={() => this.props.history.push(el.path)}
                >
                  {el.title}
                </Nav.Link>
              ))}
            </Nav>
          </Navbar.Collapse>
       
        </Navbar> */}

        <Menu
          theme="dark"
          selectedKeys={[this.state.current]}
          onClick={this.handleClick}
          mode="horizontal"
          overflowedIndicator={<MenuUnfoldOutlined />}
          style={{backgroundColor: "black", justifyContent: "center"}}
        >
          {menu.map((el, index) => {
            if (el.hasSubnav) {
              return (
                <SubMenu title={el.title} key={index}>
                  {el.subnav.map((el1, index1) => {
                    if (el1.hasSubnav) {
                      return (
                        <SubMenu title={el1.title} key={index1}>
                          {el1.subnav.map((el2, index2) => (
                            <Menu.Item key={el2.path}>{el2.title}</Menu.Item>
                          ))}
                        </SubMenu>
                      );
                    } else {
                      return <Menu.Item key={el1.path}>{el1.title}</Menu.Item>;
                    }
                  })}
                </SubMenu>
              );
            } else {
              return (
                <Menu.Item key={el.path} style={{ fontSize: "11px", color: "white", fontFamily: "Open Sans !important", fontWeight: 600 }}>
                  {el.title}
                </Menu.Item>
              );
            }
          })}
        </Menu>
      </div>
    );
  }
}

export default DepartmentNavigation;
