import React, { Component } from 'react'
import { Card } from 'react-bootstrap';
import TitleStyle from '../TitleStyle';
import { Container, Typography, Table, TableHead, TableBody, TableRow, TableCell, Grid, CardContent, CardActions, Button } from '@material-ui/core';
import { PostData } from '../../api/service';
import Icon from '@material-ui/core/Icon'
import ShowImage from './ShowImage';



export default class DepartmentNotifications extends Component {
    state = {
        data: [],
        isLoaded: false
    }

    componentDidMount() {
        let d = {
            dept_code: this.props.data.dept_code,
            type: 'DEPT_NOTICE'
        }

        PostData(`/getnoticesbytypedept`, d)
            .then((resp) => {
                this.setState({
                    data: resp,
                    isLoaded: true
                })
            })
    }

    b64toBlob(b64Data, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;

        var byteCharacters = atob(b64Data);
        var byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);

            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        var blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }


    showFile = (blob, index) => {



        var blob1 = this.b64toBlob(blob.data, blob.mime, 512);
        var blobUrl = URL.createObjectURL(blob1);


        var link = document.createElement('a');
        link.href = blobUrl;
        link.download = `${blob.type}_${Math.random() * 1000}.${blob.ext}`;
        link.click();




    }

    handleClick(data) {

        PostData(`/getfiledata`, data)
            .then((resp) => {

                //console.log(resp)
                this.showFile(resp, 1)
            })

    }

    modify_name = (name) => {
        return name.slice(0, 15) + '..';
    }
    render() {
        return (
            <div>
                <Container>
                    <TitleStyle
                        title={`Notifications`}
                    />

                    <br />

                    {this.state.isLoaded ? (
            <Grid container spacing={2}>
              {this.state.data.map((el, index) => (
                <Grid item key={index} xs={12} sm={12} lg={12} md={12}>
                  <Card>
                    {/* <div style={{ width: "100%", height: "500px" }}>
                      <ShowImage
                        files={el.upload_info}
                        nos="1"
                        height="500px"
                        width="100%"
                      />
                    </div> */}
                    <div>
                      <CardContent>
                        <Typography variant="subtitle2" gutterBottom>
                          {el.date}
                        </Typography>
                        <Typography variant="subtitle2" gutterBottom>
                          {el.title}
                        </Typography>
                        <Typography
                          variant="body2"
                          dangerouslySetInnerHTML={{ __html: el.description }}
                        ></Typography>
                      </CardContent>

                      {Array.isArray(el.upload_info) ? (
                        <CardActions>
                          {el.upload_info.map((el1, index1) => (
                            <Button
                              size="small"
                              key={index1}
                              href={el1.url}
                              target="_blank"
                              //onClick={this.handleClick.bind(this, el1)}
                              color="primary"
                            >
                              View {index1 + 1}
                            </Button>
                          ))}
                        </CardActions>
                      ) : null}

                      {/* <CardActions>
                        <Link to={`/department/${this.props.data.dept_code}/singleevent?eventId=${el.id}`}>
                          <Button type="primary">View Event</Button>
                        </Link>
                      </CardActions> */}
                    </div>
                  </Card>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Typography>Loading . . . </Typography>
          )}
                </Container>
            </div>
        )
    }
}
