import React from "react";
import { Navbar, NavbarBrand } from "reactstrap";
import "./PanduNav.css";
import SubNav3 from "./SubNav3";
import { NavLink, MDBBtn } from "mdbreact";
import { Link } from "react-router-dom";
import { Typography, Grid, Button } from "@material-ui/core";

export default class PanduNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sticky: false,
    };
  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  //   handleScroll = () => {
  //     if (window.scrollY < 150) {
  //       this.setState({
  //         sticky: false
  //       })
  //     } else if (window.scrollY > 150) {
  //       this.setState({
  //         sticky: true
  //       })
  //     }
  //   }

  render() {
    return (
      <div>
        <SubNav3
          sticky={this.state.sticky}
          width={`200px`}
          color={`#1E3760`}
          backgroundOfNav={`white`}
          backgroundColor={`#ED8443`}
          sticky={this.state.sticky}
          textColor={`white`}
          history={this.props.history}
        />
       
        <div className="m-none">
          <Navbar expand="md" className="main-nav-class" style={{backgroundColor: (this.props.fromdept == true) ? "black" : "transperant" }}>
            <a href="/">
              <img
                className="logo-img"
                src={"/images/tnclogo.png"}
                style={{ width: 100 }}
                alt="logo"
              />
            </a>
            <div
              className="navbar-div-text"
              style={{
                flexBasis: "auto",
                margin: "15px",
                padding: "0 10px",
                borderRight: "1px solid white",
              }}
            >
              <Typography
                className="nav-bar-div-text-h"
                variant="h3"
                style={{ fontWeight: 500 }}
              >
                {this.props.data != undefined ? (
                  <span>{this.props.data.dept_name_as}</span>
                ) : (
                  <span style={{ fontFamily: "Open Sans", fontWeight: 900 }}>
                    THONG NOKBE COLLEGE
                  </span>
                )}
              </Typography>
              <Typography
                className="nav-bar-div-text-h"
                variant="h3"
                style={{ fontWeight: 500 }}
              >
                {this.props.data != undefined ? (
                  <span>{this.props.data.dept_name}</span>
                ) : (
                  <img
                    src="/images/tnctext-white.png"
                    style={{ width: "10em" }}
                    alt="tnc"
                  />
                )}
              </Typography>
            </div>
            <div>
              <h6 className="mrinh6">Karbi Anglong -782441 , Assam, India</h6>
              <h6 className="mrinh6">+91 6026020188</h6>
              <h6 className="mrinh6">principaltnc@gmail.com</h6>
            </div>
            <br />
          </Navbar>
        </div>

        {/* for mobile */}
        <div className="m-display">
          <Navbar expand="md" className="main-nav-class-110 m-display">
            <a href="/">
              <img
                className="logo-img"
                src={"/images/tnclogo.png"}
                alt="logo"
              />
            </a>
            <div
              className="navbar-div-text"
              style={{
                flexBasis: "auto",
                margin: "15px",
                padding: "0 10px",
              }}
            >
              <Typography className="nav-bar-div-text-h" variant="h1">
                {this.props.data != undefined ? (
                  <span style={{ color: "white" }}>
                    {this.props.data.dept_name_as}
                  </span>
                ) : (
                  <span
                    style={{
                      color: "white",
                      fontFamily: "Open Sans",
                      fontWeight: 700,
                    }}
                  >
                    THONG NOKBE COLLEGE
                  </span>
                )}
              </Typography>
              <Typography className="nav-bar-div-text-h" variant="h4">
                {this.props.data != undefined ? (
                  <span>{this.props.data.dept_name}</span>
                ) : (
                  <img
                    src="/images/tnctext-white.png"
                    style={{ width: "10em" }}
                    alt=""
                  />
                )}
              </Typography>
              <div style={{ textAlign: "center", marginTop: 10 }}>
                <h6 className="mrinh6">Karbi Anglong -782441 , Assam, India</h6>
                <h6 className="mrinh6">+91 6026020188</h6>
                <h6 className="mrinh6">principaltnc@gmail.com</h6>
              </div>
            </div>
          </Navbar>
        </div>

       
      </div>
    );
  }
}
