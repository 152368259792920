import React from 'react'

export default function BreadCrumbOwn({title}) {
  return (
    <div className="breadcrumb-own-main">
    
        <div className="breadcrumb-own-main-title">{title}</div>
        <div className="breadcrumb-overlay">&nbsp;</div>
    </div>
  )
}
