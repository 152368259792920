import React, { Component } from 'react'
import {  MDBRow, MDBCol, MDBCard, MDBCardImage, MDBCardBody, MDBCardTitle, MDBBtn, MDBCardFooter } from 'mdbreact';
import './department.css'
import TitleStyle from '../TitleStyle';
import HomePageNews from '../News/HomePageNews';
import {Typography,Container} from '@material-ui/core'

export default class HomePage extends Component {
  render() {
    return (
      <div >
        <Container>
          <MDBRow>
            <MDBCol  lg="8" sm="12" md="8" xs="12">
              <TitleStyle
                title={`Department at a glance`}
              />
              <Typography
                dangerouslySetInnerHTML={{__html: this.props.data.department_about}}
              style ={{textAlign:'justify'}}> 

              </Typography>
            </MDBCol>

            <MDBCol lg="4" sm="12" md="4" xs="12">
            <TitleStyle
                title={`Departmental Notification`}
              />
              <HomePageNews dept_code={this.props.data.dept_code} type={`DEPT_NOTICE`} />


              <TitleStyle
                title={`News & Events`}
              />
              <HomePageNews dept_code={this.props.data.dept_code} type={`DEPTEVENT`} />
            </MDBCol>
          </MDBRow>
          <br></br>
          
        </Container>
      </div>

    )
  }
}
