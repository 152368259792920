import React, { Component } from "react";
import { Link } from "react-router-dom";
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardHeader } from "mdbreact";
import HomePageNews from "./News/HomePageNews";
import { GetData } from "../api/service";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Grid,
  Typography,
} from "@material-ui/core";
import { Button, Modal } from "antd";
import moment from "moment";
import { DownloadOutlined } from "@ant-design/icons";
import { History } from "./PanduAbout/AboutDescription";
export default class HomePagePandu extends Component {
  state = {
    events: [],
    isLoaded: false,
    showHoliday: false,
    holidayData: {},
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    GetData(`/getupcomingevents`).then((resp) => {
      console.log("events", resp);
      this.setState((prevState) => {
        return {
          ...prevState,
          events: resp,
          isLoaded: true,
        };
      });
    });

    GetData(`/checkiftodayisholiday`).then((resp) => {
      if (resp != null) {
        this.setState(
          (prevState) => {
            return {
              ...prevState,
              showHoliday: resp.status,
              holidayData: {
                ...resp.data,
              },
            };
          },
          () => {
            this.showHoliday();
          }
        );
      }
    });
  };

  showHoliday() {
    if (this.state.showHoliday) {
      Modal.info({
        title: this.state.holidayData?.title,
        content: (
          <div>
            <div>
              {Array.isArray(this.state.holidayData.upload_info) &&
                this.state.holidayData.upload_info.map((el) => (
                  <img src={el.url} style={{ width: "100%" }} />
                ))}
            </div>
            From :{" "}
            {moment(this.state.holidayData?.start_date).format("DD/MM/YYYY")} to{" "}
            {moment(this.state.holidayData?.end_date).format("DD/MM/YYYY")}
            <div
              style={{ margin: 0 }}
              dangerouslySetInnerHTML={{
                __html: this.state.holidayData?.description,
              }}
            ></div>
            {/* <br />
          <br />
          <br />
          {this.state.holidayData?.upload_info?.map((el, index) => <>
            <Button type="primary" icon={<DownloadOutlined />} size="small" onClick={() => window.open(`${el.url}}`)}>File {++index}</Button>&nbsp;
          </>
          )} */}
          </div>
        ),
        onOk() {},
        centered: true,
      });
    }
  }
  render() {
    return (
      <div className="fontFix">
        <div
          style={{
            width: "100%",
            height: "85vh",
            background: "url('/images/bg2.png')",
            backgroundRepeat: "no repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            
          
          }}
          className="main-hero-image"
        >
          {this.state.isLoaded && (
            <div
              style={{
                background: "linear-gradient(180deg,#000A,#0005,#000A)",
                height: "100%",
                width: "100%",
                // display: "flex",
                // justifyContent: "center",
                padding: "2em 0",
                alignItems: "center",
              }}
            >
              {Array.isArray(this.state.events) &&
                this.state.events.length > 0 && (
                  <div style={{}}>
                    <AliceCarousel
                      items={this.state.events.map((el, index) => (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                            marginTop: 40,
                          }}
                          key={index}
                        >
                          <p
                            style={{
                              color: "white",
                              fontSize: "1.7em",
                              fontWeight: "600",
                              textAlign: "center",
                              marginBottom: "1em",
                            }}
                          >
                            Upcoming Events
                          </p>
                          <br />
                          <br />

                          <div className="hr" style={{ width: "50%" }}></div>
                          <div style={{ margin: "4em 0" }}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                margin: "2em 0",
                              }}
                            >
                              <div className="round-date">
                                {el.date_no} {el.month}
                              </div>
                            </div>
                            <div
                              className=""
                              style={{
                                textAlign: "center",
                                color: "white",
                                fontSize: "1.5em",
                                fontWeight: "600",
                              }}
                            >
                              {el.title}
                            </div>
                          </div>
                          <div
                            className="hr"
                            style={{ marginTop: "1em", width: "50%" }}
                          >
                            &nbsp;
                          </div>
                        </div>
                      ))}
                      responsive={{
                        0: { items: 1 },
                        1024: { items: 1 },
                      }}
                      autoPlayInterval={2000}
                      autoPlayDirection="rtl"
                      autoPlay={true}
                      //fadeOutAnimation={true}
                      mouseDragEnabled={true}
                      playButtonEnabled={false}
                      keysControlDisabled={true}
                      dotsDisabled={true}
                      buttonsDisabled={true}
                    />
                  </div>
                )}
            </div>
          )}
        </div>

        {/* <MDBRow>
          <RecentNews />
        </MDBRow> */}

        <MDBContainer>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <div
                className="content my-4 p-4 p text-justify"
                style={{
                  fontWeight: "400",
                  fontSize: "1.2em",
                }}
              >
                <Typography
                  variant="h5"
                  style={{ fontWeight: 700 }}
                  gutterBottom
                >
                  About The College
                </Typography>
                <Typography variant="body1">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Thong
                  Nokbe College, situated at Dokmoka just at the foot of
                  Mahamaya Hills in the district of Karbi Anglong is one of the
                  promising institutions of the district imparting Arts
                  education at Higher Secondary and Degree Level drawing the
                  bulk of poor students who cannot afford to pursue higher
                  studies in other colleges. The college is named after “THONG
                  NOKBE” to commemorate the legendary Karbi folk hero who shed
                  his blood in the altar of freedom and progress against feudal
                  oppression.
                  <br />
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The College
                  was established in the year 1984 by the sweat and toil of the
                  local people as a symbol of their concern for upliftment of
                  higher education in this backward district. Starting with an
                  enrollment of mearge 55 students the college has over the
                  years, attained full fledged growth having an enrolment of
                  1800 plus students and registering progress in all activities.
                  The college is permanently affiliated to the Assam University.
                  Silchar, a central university that came into existence through
                  Assam (Central) University Act,1989 (Established under an Act
                  of Parliament), and provincialised under provision of the
                  Assam Venture Educational Institutions (Provincialisation of
                  Services) Act’ 2011. . . .{" "}
                  <a href="/aboutus/about">Read More</a>
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <div
                style={{
                  backgroundColor: "#EBEBEB",
                  marginTop: 10,
                  padding: 0,
                }}
              >
                <div style={{ textAlign: "center", marginTop: "50px" }}>
                  <img
                    src={`/images/thongnokbe.jpeg`}
                    style={{ width: "25%", paddingTop: 10 }}
                  />
                </div>

                <div style={{ padding: 20 }}>
                  <Typography
                    variant="h6"
                    gutterBottom
                    style={{ textAlign: "center", fontWeight: 700 }}
                  >
                    Our History
                  </Typography>

                  <Typography variant="body1">
                    <strong>THONG NOKBE</strong> lived during the medieval
                    period fighting against Khasi feudal oppression and restored
                    dignity and freedom to his fellow Karbis. His exploits are
                    glorified in folklores and legends and is adored by all
                    Karbis as the first of their national heroes.
                  </Typography>
                </div>
              </div>
            </Grid>
          </Grid>

          <br />

          {/* <div className="row">
            <div className="col-md-4 gapround">
              <a href="/departments">
                <div className="round">
                  <h4>DEPARTMENTS</h4>
                </div>
              </a>
            </div>
            <div className="col-md-4 gapround">
              <a href="./iqac/streategies">
                <div className="round">
                  <h4>IQAC</h4>
                </div>
              </a>
            </div>
            <div className="col-md-4 gapround">
              <a href="./committees">
                <div className="round">
                  <h4>COMMITTEES</h4>
                </div>
              </a>
            </div>


          </div> */}
        </MDBContainer>

        <div style={{ backgroundColor: "#f9f9f9" }}>
          <MDBContainer>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} lg={4} md={4}>
                <div
                  style={{
                    height: 180,
                    display: "flex",
                    justifyContent: "flex-start",
                    backgroundColor: "none",
                  }}
                >
                  <div
                    style={{
                      flexBasis: "40%",
                      backgroundColor: "#ED8443",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src="/images/icons/department-icon.jpg"
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div
                    style={{
                      padding: 10,
                      paddingLeft: 20,
                      flexBasis: "60%",
                      position: "relative",
                    }}
                  >
                    <Typography
                      component="h5"
                      variant="h5"
                      style={{ fontWeight: 700 }}
                    >
                      Our Departments
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary">
                      Our college contains 8 Departments.
                    </Typography>
                    <br />
                    <div
                      style={{
                        textAlign: "right",
                        position: "absolute",
                        bottom: "10px",
                        right: "10px",
                      }}
                    >
                      <Button
                        href="/departments"
                        type="primary"
                        style={{
                          fontWeight: 700,
                          backgroundColor: "#ED8443",
                          borderColor: "#ED8443",
                        }}
                      >
                        Visit Departments
                      </Button>
                    </div>
                  </div>
                </div>
              </Grid>

              <Grid item xs={12} sm={12} lg={4} md={4}>
                <div
                  style={{
                    height: 180,
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <div
                    style={{
                      flexBasis: "40%",
                      backgroundColor: "#ED8443",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src="/images/icons/iqac-icon.jpg"
                      style={{ width: `90%` }}
                    />
                  </div>
                  <div
                    style={{
                      padding: 10,
                      paddingLeft: 20,
                      flexBasis: "60%",
                      position: "relative",
                    }}
                  >
                    <Typography
                      component="h5"
                      variant="h5"
                      style={{ fontWeight: 700 }}
                    >
                      IQAC
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary">
                      Internal Quality Assesment Committee
                    </Typography>
                    <br />
                    <div
                      style={{
                        textAlign: "right",
                        position: "absolute",
                        bottom: "10px",
                        right: "10px",
                      }}
                    >
                      <Button
                        href="/iqac/streategies"
                        type="primary"
                        style={{
                          fontWeight: 700,
                          backgroundColor: "#ED8443",
                          borderColor: "#ED8443",
                        }}
                      >
                        Visit IQAC
                      </Button>
                    </div>
                  </div>
                </div>
              </Grid>

              <Grid item xs={12} sm={12} lg={4} md={4}>
                <div
                  style={{
                    height: 180,
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <div
                    style={{
                      flexBasis: "40%",
                      backgroundColor: "#ED8443",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src="/images/icons/committee-icon.jpg"
                      style={{ width: `90%` }}
                    />
                  </div>
                  <div
                    style={{
                      padding: 10,
                      paddingLeft: 20,
                      flexBasis: "60%",
                      position: "relative",
                    }}
                  >
                    <Typography
                      component="h5"
                      variant="h5"
                      style={{ fontWeight: 700 }}
                    >
                      Committees
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary">
                      Committees associated within this college
                    </Typography>
                    <br />
                    <div
                      style={{
                        textAlign: "right",
                        position: "absolute",
                        bottom: "10px",
                        right: "10px",
                      }}
                    >
                      <Button
                        href="/committees"
                        type="primary"
                        style={{
                          fontWeight: 700,
                          backgroundColor: "#ED8443",
                          borderColor: "#ED8443",
                        }}
                      >
                        Visit Committees
                      </Button>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </MDBContainer>
        </div>

        <div className=" py-4" style={{ backgroundColor: "#f9f9f9" }}>
          <MDBContainer>
            <p
              style={{
                color: "#b94735",
                fontSize: "2.7em",
                fontWeight: "600",
                textAlign: "center",
                margin: "2em 0 1em 0",
              }}
            >
              Notifications
            </p>

            <div className="row news-container">
              <div className="col-md-4 p-4">
                <div className="new-card-head">
                  <h2 className="subhead">Student Notification</h2>
                  <Link to="/notifications/academic">
                    <Button>View All</Button>
                  </Link>
                </div>
                <div className="noticecard">
                  <HomePageNews type="COLLEGE_ACADEMIC_NOTICE" />
                </div>
              </div>

              <div className="col-md-4 p-4">
                <div className="new-card-head">
                  <h2 className="subhead">Tenders</h2>
                  <Link to="/notifications/tender">
                    <Button>View All</Button>
                  </Link>
                </div>

                <div className="noticecard">
                  <HomePageNews type="COLLEGE_TENDER" />
                </div>
              </div>

              <div className="col-md-4 p-4">
                <div className="new-card-head">
                  <h2 className="subhead">Announcements</h2>
                  <Link to="/notifications/administrative">
                    <Button>View All</Button>
                  </Link>
                </div>

                <div className="noticecard">
                  <HomePageNews type="COLLEGE_ADMIN_NOTICE" />
                </div>
              </div>
            </div>
          </MDBContainer>
        </div>

        <MDBContainer>
          <div className="row my-4">
            <div className="col-md-4 col-sm-12 my-2">
              <a href="./importantfile">
                <div
                  className="card p-4 text-center text-dark"
                  style={{ background: "transparent", cursor: "pointer" }}
                >
                  <div className="d-flex justify-content-center">
                    <img
                      src="/images/icons/importantfiles.png"
                      style={{
                        height: "100px",
                        width: "100px",
                        margin: "10px",
                      }}
                    />
                  </div>
                  <h4>Important Informations</h4>
                  <p>
                    Including all the relevent files of the College in various
                    contexts...
                  </p>
                </div>
              </a>
            </div>

            <div className="col-md-4 col-sm-12 my-2">
              <a href="./organo">
                <div
                  className="card p-4 text-center text-dark"
                  style={{ background: "transparent", cursor: "pointer" }}
                >
                  <div className="d-flex justify-content-center">
                    <img
                      src="/images/icons/diagram.png"
                      style={{
                        height: "100px",
                        width: "100px",
                        margin: "10px",
                      }}
                    />
                  </div>
                  <h4>Organogram</h4>
                  <p>
                    The organogram is an administrative diagram of College ....{" "}
                  </p>
                </div>
              </a>
            </div>

            <div className="col-md-4 col-sm-12 my-2">
              <a href="./emblem">
                <div
                  className="card p-4 text-center text-dark"
                  style={{ background: "transparent", cursor: "pointer" }}
                >
                  <div className="d-flex justify-content-center">
                    <img
                      src="/images/tnclogo.png"
                      style={{
                        height: "100px",
                        width: "100px",
                        margin: "10px",
                      }}
                    />
                  </div>
                  <h4>The Emblem</h4>
                  <p>
                    Shield and swords signifies an armour of protection to fight
                    against illiteracy...{" "}
                  </p>
                </div>
              </a>
            </div>
          </div>
        </MDBContainer>

        <div className="news py-4">
          <MDBContainer>
            <p
              style={{
                color: "#b94735",
                fontSize: "2.7em",
                fontWeight: "600",
                textAlign: "center",
                margin: "2em 0 0 0",
              }}
            >
              Take A Tour Of Thong Nokbe College
            </p>
            <div className="row">
              <div className="col-md-2"></div>
              <div className="col-md-8 my-4 vdamaz">
                <iframe
                  width="100%"
                  style={{ aspectRatio: "1.6410" }}
                  src="https://www.youtube.com/embed/BtDCX335d9E"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="col-md-2"></div>
            </div>
          </MDBContainer>
        </div>
      </div>
    );
  }
}
