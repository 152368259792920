import React, { Component } from "react";
import { Container, Grid, Typography } from "@material-ui/core";
import TitleStyle from "../TitleStyle";
import { PostData } from "../../api/service";
import history from "../../history";

import queryString from "query-string";
import { BackTop, Card, Tabs } from "antd";
import FacultyView from "./FacultyView";

import {
  UserOutlined,
  BoxPlotOutlined,
  BankOutlined,
  FacebookOutlined,
  LinkedinOutlined,
  GoogleOutlined,
  DribbbleOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import PanduNav from "../HeaderComponent/PanduNav";

const { TabPane } = Tabs;

const smallTab = [
  // {
  //     title: 'Home',
  //     active: 0
  // },
  {
    title: "Basic Details",
    value: "basic",
    active: 1,
  },
  {
    title: "Address",
    value: "address",
    active: 2,
  },
  {
    title: "Academic Details",
    value: "phd",
    active: 8,
  },
  // {
  //     title: 'Contact Details',
  //     value: 'contact',
  //     active: 3
  // },
  {
    title: "Seminar/Workshop/Conference Participation",
    value: "seminar",
    active: 4,
  },
  {
    title: "Research Projects (On-going/Completed)",
    value: "research",
    active: 5,
  },
  {
    title: "Research Guideship",
    value: "guideship",
    active: 13,
  },
  {
    title: "Publications",
    value: "publications",
    active: 6,
  },
  {
    title: "Orientation Programme/Refresher Course/Short Term course",
    value: "workshop",
    active: 7,
  },

  {
    title: "Involvement in College",
    value: "involvement",
    active: 9,
  },
  {
    title: "Any Other Infromation",
    value: "cocurricular",
    active: 10,
  },
  {
    title: "Awards & Achievements",
    value: "awards",
    active: 11,
  },
  {
    title: "Links",
    value: "others",
    active: 12,
  },
];

class FacultySingleLanding extends Component {
  state = {
    data: {},
    dept_code: this.props.match.params.dept_code,
    dept_name: "",
    isLoaded: false,
    active: 1,
    activeData: "",
    activeTitle: "",
    sameDepartment: [],
    isSameDepartmentLoaded: false,
    rr: [],
  };

  myRef = React.createRef([]);

  executeScroll = (active) => {
    console.log(this.state.rr);
    this.state.rr[active].scrollIntoView({ behavior: "smooth" });
  };

  getMyProfileData = (id) => {
    PostData(`/getmyprofiledata`, {
      id,
    })
      .then((resp) => {
        if (resp != false) {
          this.setState({
            data: resp,
            isLoaded: true,
            active: 1,
            dept_code: this.props.dept_code,
            dept_name: resp.dept_name,
            activeData: resp.basic,
            activeTitle: "Basic Details",
          });
        } else {
          history.push(`/department/${this.props.dept_code}/faculty`);
        }
      })
      .catch((err) => {
        alert(`Error`);
      });
  };

  componentDidMount() {
    let search = this.props.location.search;

    let searchObj = queryString.parse(search);

    if (searchObj.id !== undefined) {
      this.getMyProfileData(searchObj.id);
      this.loadOtherData();
    } else {
      history.push(`/department/${this.props.dept_code}/faculty`);
    }
  }

  callback = (key) => {
    this.executeScroll(key);

    let kk = smallTab.filter((el) => el.active == key);

    if (kk.length > 0) {
      this.setState({
        active: key,
        //activeData: this.state.data[kk[0].value],
        activeData: this.state.data,
        activeTitle: kk[0].title,
      });
    }
  };

  loadOtherData = () => {
    let d = {
      dept_code: this.state.dept_code,
      type: "FACULTY",
    };

    PostData(`/getemployeebytype`, d).then((resp) => {
      let search = this.props.location.search;
      let searchObj = queryString.parse(search);
      let resp1 = resp.filter((el) => el.id != searchObj.id);

      this.setState({
        sameDepartment: resp1,
        isSameDepartmentLoaded: true,
      });
    });
  };

  render() {
    return (
      <div>
      <PanduNav history={this.props.history} data={this.state.data} fromdept={true} />


        <div style={{ marginTop: 20, marginBottom: 20 }}>
          <BackTop />
          {this.state.isLoaded && (
            <Container>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={3} sm={12} md={3}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {Array.isArray(this.state.data.dp) &&
                    this.state.data.dp.length > 0 ? (
                      <React.Fragment>
                        <img
                          alt=""
                          src={this.state.data.dpurl[0]}
                          style={{
                            width: "200px",
                            boxShadow: "1px 2px 10px rgba(0,0,0,0.3)",
                          }}
                        />
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <img
                          alt=""
                          src={"/images/faculty/default.jpg"}
                          style={{
                            width: "100px",
                            boxShadow: "1px 2px 10px rgba(0,0,0,0.3)",
                          }}
                        />
                      </React.Fragment>
                    )}

                    <br />
                  </div>

                  <div style={{ marginTop: 10 }}>
                    <Tabs
                      defaultActiveKey="0"
                      type="card"
                      tabPosition="left"
                      onChange={this.callback}
                    >
                      {smallTab.map((el, index) => (
                        <TabPane tab={el.title} key={index} />
                      ))}
                    </Tabs>
                  </div>

                  <br />

                  <div>
                    {/* Links Tab */}

                    {this.state.data.others != null && (
                      <div style={{ listStyleType: "none" }}>
                        {this.state.data.others.facebook_link != undefined && (
                          <li
                            style={{
                              marginBottom: 10,
                              backgroundColor: "blue",
                              padding: 10,
                            }}
                          >
                            <a
                              href={this.state.data.others.facebook_link}
                              target="_blank"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                color: "white",
                                fontWeight: 700,
                              }}
                            >
                              <FacebookOutlined /> &nbsp;&nbsp;Facebook Link
                            </a>
                          </li>
                        )}

                        {this.state.data.others.linkedin_link != "" && (
                          <li
                            style={{
                              marginBottom: 10,
                              backgroundColor: "blue",
                              padding: 10,
                            }}
                          >
                            <a
                              href={this.state.data.others.linkedin_link}
                              target="_blank"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                color: "white",
                                fontWeight: 700,
                              }}
                            >
                              <LinkedinOutlined /> &nbsp;&nbsp;LinkedIn Link
                            </a>
                          </li>
                        )}

                        {this.state.data.others.google_scholar != "" && (
                          <li
                            style={{
                              marginBottom: 10,
                              backgroundColor: "blue",
                              padding: 10,
                            }}
                          >
                            <a
                              href={this.state.data.others.google_scholar}
                              target="_blank"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                color: "white",
                                fontWeight: 700,
                              }}
                            >
                              <GoogleOutlined /> &nbsp;&nbsp;Google Scholars'
                              Link
                            </a>
                          </li>
                        )}

                        {this.state.data.others.wiki_link != "" && (
                          <li
                            style={{
                              marginBottom: 10,
                              backgroundColor: "blue",
                              padding: 10,
                            }}
                          >
                            <a
                              href={this.state.data.others.wiki_link}
                              target="_blank"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                color: "white",
                                fontWeight: 700,
                              }}
                            >
                              <img
                                src="https://en.wikipedia.org/static/images/project-logos/enwiki-2x.png"
                                style={{ width: 25, height: 25 }}
                              />{" "}
                              &nbsp;&nbsp;Wikipedia Link
                            </a>
                          </li>
                        )}
                        {this.state.data.others.orchid_link != "" && (
                          <li
                            style={{
                              marginBottom: 10,
                              backgroundColor: "blue",
                              padding: 10,
                            }}
                          >
                            <a
                              href={this.state.data.others.orchid_link}
                              target="_blank"
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  color: "white",
                                  fontWeight: 700,
                                }}
                              >
                                <img
                                  src="/images/icons/orchid-logo.png"
                                  style={{ width: 25, height: 25 }}
                                />{" "}
                                &nbsp;&nbsp;Orcid ID
                              </div>

                              <a
                                style={{
                                  color: "white",
                                  fontWeight: 700,
                                  paddingLeft: 30,
                                }}
                              >
                                {this.state.data.others.orchid_link}
                              </a>
                            </a>
                          </li>
                        )}
                      </div>
                    )}
                  </div>

                  <br />

                  <div>
                    <div
                      style={{
                        borderLeft: "solid 1px black",
                        paddingLeft: 10,
                        padding: 10,
                        marginBottom: 10,
                        backgroundColor: "#f9f9f9",
                      }}
                    >
                      Department
                    </div>
                    <Link to={`/department/${this.props.dept_code}`}>
                      <div
                        style={{
                          paddingTop: 10,
                          paddingBottom: 10,
                          display: "flex",
                          alignItems: "center",
                          fontSize: 12,
                          fontWeight: 700,
                        }}
                      >
                        <BankOutlined /> &nbsp;&nbsp; {this.state.dept_name}
                      </div>
                    </Link>

                    <br />
                    <br />

                    <div
                      style={{
                        borderLeft: "solid 1px black",
                        paddingLeft: 10,
                        padding: 10,
                        marginBottom: 10,
                        backgroundColor: "#f9f9f9",
                      }}
                    >
                      Same Department ({this.state.sameDepartment.length})
                    </div>
                    {Array.isArray(this.state.sameDepartment) &&
                      this.state.sameDepartment.map((el, index) => (
                        <a
                          href={`/department/${this.props.dept_code}/faculty/single?id=${el.id}&name=${el.name}`}
                        >
                          <div
                            key={index}
                            style={{
                              paddingTop: 10,
                              paddingBottom: 10,
                              display: "flex",
                              alignItems: "center",
                              fontSize: 12,
                              fontWeight: 700,
                            }}
                          >
                            <UserOutlined /> &nbsp;&nbsp; {el.name}
                          </div>
                        </a>
                      ))}
                  </div>
                </Grid>

                <Grid
                  item
                  xs={12}
                  lg={8}
                  sm={12}
                  md={8}
                  style={{ textAlign: "left", backgroundColor: "#f9f9f9" }}
                >
                  <div>
                    <Card>
                      <Typography
                        variant="subtitle1"
                        style={{
                          fontWeight: 700,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <UserOutlined /> &nbsp;&nbsp;{this.state.data.name}
                      </Typography>
                      <Typography
                        variant="body1"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <BoxPlotOutlined /> &nbsp;&nbsp;
                        {this.state.data.designation}
                      </Typography>
                      <Typography
                        variant="body1"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <BankOutlined /> &nbsp;&nbsp;{this.state.dept_name}
                      </Typography>
                    </Card>
                  </div>

                  <br />

                  {smallTab.map((el, index) => (
                    <div
                      key={index}
                      ref={(ref) => {
                        this.state.rr[index] = ref;
                      }}
                    >
                      <Card key={index} style={{ marginBottom: 10 }}>
                        <FacultyView
                          title={el.title}
                          {...this.state.data}
                          active={el.active}
                        />
                      </Card>
                    </div>
                  ))}
                </Grid>
                {/* <Grid
                item
                xs={12}
                lg={2}
                sm={12}
                md={2}
                style={{ textAlign: 'left' }}
              >
                
              </Grid> */}
              </Grid>
            </Container>
          )}

          {!this.state.isLoaded && (
            <Container>
              <div style={{ textAlign: "center" }}>Loading....</div>
            </Container>
          )}
        </div>
      </div>
    );
  }
}

export default FacultySingleLanding;
